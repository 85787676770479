/* eslint-disable camelcase */

const MAPS_LOCATION = {
  br:
    'Ei1Fc3RyYWRhIE11bi4gLSBFeHRyZW1hLCBNRywgMzc2NDAtMDAwLCBCcmF6aWwiLiosChQKEgnBCHTi11LJlBGSj2hjaVMVAxIUChIJ39NjMZurzpQRpgWxdJSw0gQ',
  global: 'ChIJOa-LYOgJxkcRYUcg-5RSGjM',
}

export default class Ga4Parser {
  constructor(state) {
    this.store = state.storeCode
    this.currency = state.currency
  }

  viewItemList(products) {
    return this._itemsParser(products)
  }

  viewCart(line_items) {
    return this._itemsParser(line_items)
  }

  viewItem(product) {
    return this._itemsParser(this._toArray(product))
  }

  addToWishlist(product) {
    return this._itemsParser(this._toArray(product))
  }

  addToCart(product) {
    return this._itemsParser(this._toArray(product))
  }

  removeFromCart(product) {
    return this._itemsParser(this._toArray(product))
  }

  beginCheckout(products) {
    return this._itemsParser(products)
  }

  // PRIVATE

  _toArray(product) {
    return [product]
  }

  _itemsParser(products) {
    return products
      .map(this._itemAttributesSetter)
      .map((prod) => this._additionalItemAttributes(prod, this))
  }

  _additionalItemAttributes(item, _this) {
    const { currency, store } = _this

    if (!item.promotion_id) {
      delete item.promotion_id
      delete item.promotion_name
    }

    item.currency = currency
    item.location_id = MAPS_LOCATION[store]

    return item
  }

  _itemAttributesSetter(item, index) {
    const priceDiff = item.old_price
      ? Number(Number(item.old_price) - Number(item.price))
      : 0

    const discount = priceDiff > 0 ? Number(priceDiff.toFixed(2)) : 0

    return {
      item_id: item.product_id ? String(item.product_id) : '',
      item_name: item.seo_optimized_name || item.name,
      discount,
      promotion_id: item.promotion?.promotion_event_id,
      promotion_name: item.promotion?.promotion_event_id
        ? 'Promotion Event'
        : null,
      index,
      item_brand: 'Gocase',
      item_category: item.product_type_code,
      item_variant: item.case_device_id
        ? String(item.case_device_id)
        : undefined,
      price: Number(item.price),
      quantity: item.quantity || 1,
    }
  }
}
